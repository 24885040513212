/**
* Gutenberg Color helper classes
* - rememer to set colors for backend
*/
@each $color-name, $color-value in $colors {
  .has-#{'' + $color-name}-color {
    color: $color-value !important;
  }

  .has-#{'' + $color-name}-background-color {
    background-color: $color-value !important;
  }
}

/**
* Container settings for Gutenberg Markup
*/

// force center for some gutenberg blocks
.container {
  margin-left: auto !important;
  margin-right: auto !important;
}

.wp-block-group {
  &__inner-container {
    @extend .container !optional;
  }
}

.site-content {
  > * {
    @extend .container !optional;
  }
}

.alignfull {
  max-width: 100%;

  &.full-width-shortcode {
    .wp-block-group__inner-container {
      margin: 0 !important;
      max-width: unset;
    }
  }
}

/**
* Gutenberg Blocks Styling
*/

.wp-block-cover {
  .wp-block-cover__inner-container {
    position: absolute;
    top: 0;
    height: 100%;
    max-width: breakpoint('xl');
    padding-top: 10vh;

    h1,
    h2,
    h3 {
      position: absolute;
      padding: 0.5em;
      bottom: 2em;
    }
  }
}

.wp-block-image.konfigurator-bike {
  width: 200%;
  margin-left: -100%;
}

.barracuda-bg-struktur {
  background: url('../img/barracuda-bg-struktur.png');
  background-size: 13em;
}
