// *
// * Include Fonts and define basic font ajustments and styling
// *
/* stylelint-disable */
$fonts-path: '../fonts';

/* fugaz-one-regular - latin */
@font-face {
  font-family: 'Fugaz One';
  font-style: normal;
  font-weight: 400;
  src: url($fonts-path+'/fugaz-one-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url($fonts-path+'/fugaz-one-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url($fonts-path+'/fugaz-one-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url($fonts-path+'/fugaz-one-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
       url($fonts-path+'/fugaz-one-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url($fonts-path+'/fugaz-one-v10-latin-regular.svg#FugazOne') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url($fonts-path+'/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url($fonts-path+'/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url($fonts-path+'/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url($fonts-path+'/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url($fonts-path+'/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url($fonts-path+'/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

.font-brand {
  font-family: sans-serif;
}

h1,
h2,
h3,
.is-style-default {
  @include font-size(2.5rem); 
}

.is-style-heading-size-1,
.heading-size-1 {
  @include font-size(3rem);
}

.is-style-heading-size-2,
.heading-size-2 {
  @include font-size(2.5rem);
}

.is-style-heading-size-3,
.heading-size-3 {
  @include font-size(2rem);
}

.is-style-heading-size-4,
.heading-size-4 {
  @include font-size(1.5rem);
}

.is-style-heading-size-4,
.heading-size-4,
.is-style-heading-size-3,
.heading-size-3,
.is-style-heading-size-2,
.heading-size-2,
.is-style-heading-size-1,
.heading-size-1,
h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-heading;
  text-transform: uppercase;
}
