form.hf-form {
  .row {
    max-width: 50em;
    margin: auto;

    input[type=text],
    input[type=email] {
      border: 0;
      border-bottom: 1px solid color('primary-light');
      margin: 1em;
    }

    input[type=submit] {
      padding: 0.5em 1em;
      border-radius: 1.55em;
      background: #ee7c15;
      border: 0;
      color: white;
    }

    textarea {
      border: 1px solid color('primary-light');
    }
  }
}
