/**
 * Global
 */

body {
  font-family: $font-family-base;
  overflow-x: hidden;
}

a {
  color: color('primary');
  text-decoration: none;
  background-color: transparent;
  transition: opacity 100ms ease-in-out;

  &:hover {
    opacity: 0.85;
  }
}

svg,
img,
embed,
object {
  display: block;
  height: auto;
  max-width: 100%;
}

#gdpr-cookie-consent-bar {
  .gdpr_messagebar_content {
    .group-description-buttons {
      @media (max-width: breakpoint(sm)) {
        display: flex;
        flex-direction: column;

        a {
          width: 100%;
          margin: 0.5em;
        }
      }
    }
  }
}
