.site-footer {
  background: color('primary');
  color: color('whiter');

  a {
    color: color('whiter');
  }

  img {
    max-width: 15em;
  }

  li.menu-item {
    @media (max-width: breakpoint(sm)) {
      justify-content: center;
    }

    &.page-list__item--current {
      text-decoration: underline;
      background: none;
    }
  }
}
